import React from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import searchWarranty from '../img/search_warranty.svg'
import loader from '../img/loader.svg'

const cookies = new Cookies();


class TableRow extends React.Component {
    constructor(props) {
        super(props);
        this.deleteProduct = this.deleteProduct.bind(this);
    }

    deleteProduct = (e) => {
        e.preventDefault()
        var comp = this;
        var promptUser = window.confirm("Are You Sure You Want to Delete this Product");
        if (promptUser) {
            //some code
            axios.delete(API_URL + '/api/deleteproduct/' + this.props.product._id)
                .then((response) => {
                    alert('Product Deleted')
                    comp.props.history.push('/search-product')

                })
                .catch(error => {
                    if (error.response) {
                        console.log(error)
                    }
                });
        }
        else {
            //some code

        }
    }
    calcMonths(months) {
        if (months < 12) {
            return (months % 12 + " months")
        }
        else if (months === 12) {
            return ((months / 12 | 0) + " year")
        }
        else {
            return ((months / 12 | 0) + " years and " + months % 12 + " months")
        }
    }
    render() {
        return (
            <tr>
                <td>{this.props.product.item_detail}</td>
                <td>{this.props.product.item_number}</td>
                <td>{this.props.product.device_code}</td>
                <td>{this.props.product.device_name}</td>
                <td>{this.props.product.description}</td>
                <td className='green'>{this.calcMonths(this.props.product.warranty_period)}</td>
                <td><a href={'/edit-product/' + this.props.product._id} className="uk-button uk-button-small small_slider_btn">EDIT</a></td>
                <td className='uk-preserve-width'><a data-uk-tooltip="Delete This Product" href='/search-product' onClick={this.deleteProduct} className="red"><span data-uk-icon="icon: trash; ratio: 1.2"></span>
                </a></td>
            </tr>
        )
    }
}


class SearchProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            products: [],
            products2: [],
            product_id: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        this.checkcookie()
        this.getProducts()
        this.getUser()

    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getProducts = () => {
        axios.get(API_URL + '/api/products/')
            .then((response) => {
                this.setState({
                    products: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ product_id: event.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        axios.get(API_URL + '/api/findproduct/'+this.state.product_id)
            .then((response) => {
                this.setState({
                    isProcessing: false,
                    products: new Array(response.data),
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }

    render() {
        const options = this.state.products.map((product, index) => {
            return {
                label: product.device_model,
                value: product._id,
                key: index
            }
        })
        return (
            <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                <div className='uk-width-auto'>
                    <SideNav />
                </div>
                <div className='uk-width-expand uk-padding-remove'>
                    <div className="uk-width-1-1">
                        <div className="uk-card uk-card-default uk-card-body uk-card-small">
                            <div data-uk-grid>
                                <div className='uk-width-1-3'>
                                    <img src={logo} width='100' alt='zinox logo' />
                                </div>
                                <div className='uk-width-2-3 uk-text-right'>

                                    <h5 className='uk-text-bold'> {this.state.user.name}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='uk-padding'>
                            <div className="uk-card uk-card-default uk-card-body ">
                                <div data-uk-grid className=''>
                                    <div className='uk-width-auto '>
                                        <img src={searchWarranty} width='80' alt='icon' />
                                    </div>
                                    <div className='uk-width-expand uk-margin-small-top'>
                                        <h3 className='uk-text-left uk-margin-remove'>Search</h3>
                                        <p className='uk-text-small uk-width-medium uk-margin-remove'>Search and Edit Product Information</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="uk-background-muted uk-padding">
                                    <div className="uk-margin">
                                        <h4 className='uk-text-bold'>Search Filters</h4>
                                        <form method='POST' data-uk-grid onSubmit={this.handleSubmit} >
                                            <div className="uk-width-1-3@m">
                                                <label className="uk-form-label uk-text-bold ">Device Code <span className='red'>*</span></label>
                                                <Select className="calc_input uk-margin-small-top" options={options} name='product_id' onChange={this.handleInputChange} placeholder='Search...' required />
                                            </div>

                                            <div className="uk-margin-top uk-width-auto">
                                                {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right slider_btn">Search</button>}
                                                {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Searching...</h4>}
                                                <a href='/search-product' className="uk-button uk-margin-right outline_btn">Clear Search</a>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='uk-margin'>
                                    <div className="uk-overflow-auto">
                                        <p className='uk-text-left uk-text-meta uk-margin-remove'>Showing {this.state.products.length} Products</p>
                                        <table className="uk-table uk-table-striped uk-overflow-auto">
                                            <thead>
                                                <tr>
                                                    <th className='uk-text-bold red'>Item Details</th>
                                                    <th className='uk-text-bold red'>Item Number</th>
                                                    <th className='uk-text-bold red'>Device Code</th>
                                                    <th className='uk-text-bold red'>Device Name</th>
                                                    <th className='uk-text-bold red'>Description</th>
                                                    <th className='uk-text-bold red'>Warranty Period</th>
                                                    <th className='uk-text-bold red '>&nbsp;</th>
                                                    <th className='uk-text-bold red'>&nbsp;</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.products.map(product =>
                                                        <TableRow key={product._id}
                                                            index={product._id}
                                                            product={product} />
                                                    )
                                                }
                                            </tbody>
                                            
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SearchProduct;

import React from 'react';
import axios from 'axios'
import Cookies from 'universal-cookie';
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import addProduct from '../img/add_product.svg'
import loader from '../img/loader.svg'
const cookies = new Cookies();


class ProductResult extends React.Component {
    calcMonths(months) {
        if (months < 12) {
            return (months % 12 + " months")
        }
        else if (months === 12) {
            return ((months / 12 | 0) + " year")
        }
        else {
            return ((months / 12 | 0) + " years and " + months % 12 + " months")
        }
    }
    render() {
        console.log(this.props)
        return (
            <div>
                <h3 className='uk-text-left uk-margin-large'>Product <span className='red'>{this.props.data.item_detail} - {this.props.data.item_number}</span> Has been Added </h3>
                <hr />
                <h5>Product Details</h5>
                <p><span className='uk-text-bold'>Item Detail: </span> {this.props.data.item_detail}</p>
                <p><span className='uk-text-bold'>Item Number: </span> {this.props.data.item_number}</p>
                <p><span className='uk-text-bold'>Device Code: </span> {this.props.data.device_code}</p>
                <p><span className='uk-text-bold'>Device Name: </span> {this.props.data.device_name}</p>
                <p><span className='uk-text-bold'>Description: </span> {this.props.data.description}</p>
                <p><span className='uk-text-bold'>Warranty Period: </span> {this.calcMonths(this.props.data.warranty_period)}</p>
                <a href='/add-product' className="uk-button uk-margin uk-margin-right slider_btn">Add Another Product</a>
                <a href='/dashboard' className="uk-button uk-margin slider_btn">Dashboard</a>
            </div>
        )
    }
}


class AddProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item_detail: '',
            item_number: '',
            device_code: '',
            description: '',
            device_name: '',
            warranty_period: '',
            isProcessing: false,
            showResult: false,
            product: [],
            user:[]

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        this.checkcookie()
        this.getUser()
    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '4') {
            this.props.history.push('/dashboard')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        var comp = this
        const payload = {
            item_detail: this.state.item_detail,
            item_number: this.state.item_number,
            device_code: this.state.device_code,
            device_name: this.state.device_name,
            description: this.state.description,
            warranty_period: this.state.warranty_period,
            device_model: this.state.item_detail + ' - ' + this.state.item_number
        }
        axios.post(API_URL + '/api/products/', payload)
            .then(function (response) {
                if (response.status === 201 && response.data !== '') {
                    comp.setState({
                        isProcessing: false,
                        product: response.data,
                        showResult: true
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    renderForm() {
        return (
            <div>
                <div className='uk-width-2-5@l uk-width-3-5@m'>
                    <div className="uk-background-muted uk-padding">
                        <div className="uk-margin">
                            <h4 className='uk-text-bold'>Add a Product</h4>
                            <form method='POST' onSubmit={this.handleSubmit} >
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Item Details <span className='red'>*</span></label>
                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='item_detail' placeholder="Item Details" onChange={this.handleInputChange} />
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Item Number <span className='red'>*</span></label>
                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='item_number' placeholder="Item Number" onChange={this.handleInputChange} />
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Device Code <span className='red'>*</span></label>
                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='device_code' placeholder="Device Code" onChange={this.handleInputChange} />
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Device Name <span className='red'>*</span></label>
                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='device_name' placeholder="Device Name" onChange={this.handleInputChange} />
                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Description  <span className='red'>*</span></label>
                                    <textarea className="uk-textarea calc_input  uk-margin-small-top" rows="3" placeholder="Description" name='description' onChange={this.handleInputChange}></textarea>
                                </div>

                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Warranty Period(In Months) <span className='red'>*</span></label>
                                    <input className="uk-input calc_input uk-margin-small-top" type="number" name='warranty_period' placeholder="Enter Warranty Period in Months" onChange={this.handleInputChange} />

                                    {/* <select className="uk-select calc_input uk-margin-small-top" name='warranty_period' onChange={this.handleInputChange}>
                                        <option value='' defaultValue >Select a Period</option>
                                        <option value='6'>6 Months</option>
                                        <option value='12'>12 Months</option>
                                        <option value='120'>10 Years</option>
                                    </select> */}
                                </div>
                                <div className="uk-margin-large-top">
                                    {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Add Product</button>}
                                    {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Adding New Product</h4>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        if (this.state.showResult) {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> Zinox Admin</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={addProduct} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Add a New Product</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Add New Product and Warranty Duration Period</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <ProductResult data={this.state.product} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={addProduct} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Add a New Product</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Add New Product and Warranty Duration Period</p>
                                        </div>
                                    </div>
                                    <hr />
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            )
        }
    }
}

export default AddProduct;

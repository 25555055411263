import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import searchWarranty from '../img/search_warranty.svg'
import loader from '../img/loader.svg'

const cookies = new Cookies();



class TableRow extends React.Component {
    constructor(props) {
        super(props);
        this.deleteSerial = this.deleteSerial.bind(this);
    }

    deleteSerial = (e) => {
        e.preventDefault()
        var comp = this
        var promptUser = window.confirm("Are You Sure You Want to Delete this Serial Number");
        if (promptUser) {
            //some code
            axios.delete(API_URL + '/api/deletewarrantyserials/' + this.props.serial._id)
                .then((response) => {
                    alert('Serial Deleted')
                    comp.props.history.push('/search')

                })
                .catch(error => {
                    if (error.response) {
                        console.log(error)
                    }
                });
        }
        else {
            //some code

        }
    }
    calcMonths(months) {
        if (months < 12) {
            return (months % 12 + " months")
        }
        else if (months === 12) {
            return ((months / 12 | 0) + " year")
        }
        else {
            return ((months / 12 | 0) + " years and " + months % 12 + " months")
        }
    }
    render() {
        if (this.props.serial.product_id[0] === undefined){
            console.log(this.props.serial.serial)
        }
        var today = new Date();
        var status = ""
        if (new Date(this.props.serial.warranty_end_date) >= today) {
            status = <td className='green'>Active</td>
        }
        else if (new Date(this.props.serial.warranty_end_date) < today) {
            status = <td className='red'>Expired</td>
        }
        else {
            status = <td className='black'>Inactive</td>
        }
        return (
            <tr>
                <td>{this.props.serial.serial}</td>
                {/* <td>{this.props.serial.serial}</td>
                <td>{this.props.serial.product_id[0].item_detail}</td>
                <td>{this.props.serial.product_id[0].item_number}</td>
                <td>{this.props.serial.product_id[0].device_code}</td>
                <td>{this.props.serial.product_id[0].description}</td> */}
                {this.props.serial.warranty_start_date ? <td>{new Date(this.props.serial.warranty_start_date).toDateString()}</td> : <td className='black'>Not Activated Yet</td>}
                {this.props.serial.warranty_start_date ? <td>{new Date(this.props.serial.warranty_end_date).toDateString()}</td> : <td className='black'>Not Activated Yet</td>}
                {status}
                {/* <td className='green'>{this.calcMonths(this.props.serial.warranty_period)}</td> */}
                <td className='uk-preserve-width'><a data-uk-tooltip="Delete This Serial" href='/search' onClick={this.deleteSerial} className="red"><span data-uk-icon="icon: trash; ratio: 1.2"></span>
                </a></td>
            </tr>
        )
    }
}

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serials: [],
            serial_number: ''

        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }
    componentDidMount() {
        this.checkcookie()
        this.getSerials()
        this.getUser()

    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getSerials = () => {
        axios.get(API_URL + '/api/warrantyserials/')
            .then((response) => {
                this.setState({
                    serials: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        axios.get(API_URL + '/api/findserial/' + this.state.serial_number)
            .then((response) => {
                console.log(response)
                console.log(response.data.length)
                if (response.data.length === 0) {
                    this.setState({
                        isProcessing: false,
                        serials: [],
                    })
                    console.log('empty')
                }
                if (response.data.length === 1) {
                    this.setState({
                        isProcessing: false,
                        serials: response.data,
                    })
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }


    render() {
        return (
            <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                <div className='uk-width-auto'>
                    <SideNav />
                </div>
                <div className='uk-width-expand uk-padding-remove'>
                    <div className="uk-width-1-1">
                        <div className="uk-card uk-card-default uk-card-body uk-card-small">
                            <div data-uk-grid>
                                <div className='uk-width-1-3'>
                                    <img src={logo} width='100' alt='zinox logo' />
                                </div>
                                <div className='uk-width-2-3 uk-text-right'>

                                    <h5 className='uk-text-bold'> Zinox Admin</h5>
                                </div>
                            </div>
                        </div>
                        <div className='uk-padding'>
                            <div className="uk-card uk-card-default uk-card-body ">
                                <div data-uk-grid className=''>
                                    <div className='uk-width-auto '>
                                        <img src={searchWarranty} width='80' alt='icon' />
                                    </div>
                                    <div className='uk-width-expand uk-margin-small-top'>
                                        <h3 className='uk-text-left uk-margin-remove'>Search</h3>
                                        <p className='uk-text-small uk-width-medium uk-margin-remove'>Search for Product Serial & Warranty Information</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="uk-background-muted uk-padding">
                                    <div className="uk-margin">
                                        <h4 className='uk-text-bold'>Search Filters</h4>
                                        <form method='POST' data-uk-grid onSubmit={this.handleSubmit} >
                                            <div className="uk-width-1-3@m">
                                                <label className="uk-form-label uk-text-bold ">Serial Number <span className='red'>*</span></label>
                                                <input className="uk-input calc_input uk-margin-small-top" type="text" name='serial_number' placeholder="Serial Number" onChange={this.handleInputChange} />
                                            </div>
                                      
                                            <div className="uk-margin-top uk-width-auto">
                                                {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right slider_btn">Search</button>}
                                                {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Searching...</h4>}
                                                <a href='/search' className="uk-button uk-margin-right outline_btn">Clear Search</a>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className='uk-margin'>
                                    <div className="uk-overflow-auto">
                                        <p className='uk-text-left uk-text-meta uk-margin-remove'>Showing {this.state.serials.length} Product Serials</p>
                                        <table className="uk-table uk-table-striped uk-overflow-auto" >
                                            <thead>
                                                <tr>
                                                    <th className='uk-text-bold red'>Serial Number</th>
                                                    <th className='uk-text-bold red'>Item Details</th>
                                                    <th className='uk-text-bold red'>Item Number</th>
                                                    <th className='uk-text-bold red'>Device Code</th>
                                                    <th className='uk-text-bold red'>Description</th>
                                                    <th className='uk-text-bold red'> Start Date</th>
                                                    <th className='uk-text-bold red'> End Date</th>
                                                    <th className='uk-text-bold red'> Status</th>
                                                    <th className='uk-text-bold red'>&nbsp;</th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.serials.map(serial =>
                                                        <TableRow key={serial._id}
                                                            index={serial._id}
                                                            serial={serial} />
                                                    )
                                                }
                                            </tbody>

                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}

export default Search;

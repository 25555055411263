import React from 'react';
import ReactDOM from 'react-dom';
import UIkit from 'uikit';
import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
// eslint-disable-next-line
import Style from 'uikit/dist/css/uikit.min.css';
import Icons from 'uikit/dist/js/uikit-icons';
import App from './components/App';
import Dashboard from './components/Dashboard';
import AddProduct from './components/AddProduct';
import UploadSerial from './components/UploadSerial';
import UploadSingleSerial from './components/UploadSingleSerial';
import ActivateWarranty from './components/ActivateWarranty';
import Search from './components/Search';
import Logout from './components/Logout';
import Error404 from './components/Error404';
import * as serviceWorker from './serviceWorker';
import './zinox-support.css'
import ManageUser from './components/ManageUser';
import PasswordChange from './components/PasswordChange';
import EditUser from './components/EditUser';
import SearchProduct from './components/SearchProduct';
import EditProduct from './components/EditProduct';
import BatchActivateWarranty from './components/BatchActivateWarrany';
import WarrantyCheck from './components/WarrantyCheck';


UIkit.use(Icons);

const MainApp = () => {
  return(
  <Router>
      <div>
          <Switch>
              <Route path="/" exact component={App} />
              <Route path="/password-change" exact component={PasswordChange} />
              <Route path="/dashboard" exact component={Dashboard} />
              <Route path="/add-product" exact component={AddProduct} />
              <Route path="/upload-serial" exact component={UploadSerial} />
              <Route path="/upload-single-serial" exact component={UploadSingleSerial} />
              <Route path="/activate-warranty" exact component={ActivateWarranty} />
              <Route path="/batch-activate-warranty" exact component={BatchActivateWarranty} />
              <Route path="/logout" exact component={Logout} />
              <Route path="/search" exact component={Search} />
              <Route path="/search-product" exact component={SearchProduct} />
              <Route path="/manage-user" exact component={ManageUser} />
              <Route path="/edit-user/:id" exact component={EditUser} />
              <Route path="/edit-product/:id" exact component={EditProduct} />
              <Route path="/warranty-check" exact component={WarrantyCheck} />
              <Route component={Error404} />
          </Switch>
      </div>
  </Router>)
}

ReactDOM.render(
    <MainApp />,
  document.getElementById('root')
);
serviceWorker.unregister();

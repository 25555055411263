import React from 'react';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from './SideNav'
import logo from '../img/logo.svg'
import manageUser from '../img/manage_user.svg'
import Cookies from 'universal-cookie';
import loader from '../img/loader.svg'

const cookies = new Cookies();

class TableRow extends React.Component {

    deactivateUser = (e) => {
        e.preventDefault()
        var promptUser = window.confirm("Are You Sure You Want to Deactivate this User? \n\nThey will be unable to access this platform until their Access is reactivated. ");
        if (promptUser) {
            axios.put(API_URL + '/api/deactivateuser/' + this.props.user._id)
                .then((response) => {
                    alert('User Deactivated')
                    window.location = '/manage-user'

                })
                .catch(error => {
                    if (error.response) {
                        console.log(error)
                    }
                });
        }
    }
    activateUser = (e) => {
        e.preventDefault()
        var promptUser = window.confirm("Are You Sure You Want to Activate this User? \n\nThey will be able to access this platform. ");
        if (promptUser) {
            axios.put(API_URL + '/api/activateuser/' + this.props.user._id)
                .then((response) => {
                    alert('User Activated')
                    window.location = '/manage-user'

                })
                .catch(error => {
                    if (error.response) {
                        console.log(error)
                    }
                });
        }
    }
    deleteUser = (e) => {
        e.preventDefault()
        var promptUser = window.confirm("Are You Sure You Want to Delete this User? \n\nThey will be removed completely from the Database. ");
        if (promptUser) {
            axios.delete(API_URL + '/api/deleteuser/' + this.props.user._id)
                .then((response) => {
                    alert('User Deleted')
                    window.location = '/manage-user'

                })
                .catch(error => {
                    if (error.response) {
                        console.log(error)
                    }
                });
        }
    }

    render() {
        console.log(this.props.user)

        let adminStats;
        if (this.props.user.status === 0) {
            adminStats = <td className='black'>Not Activated</td>;
        }
        else if (this.props.user.status === 1) {
            adminStats = <td className='green'>Active</td>
        }
        else {
            adminStats = <td className='red'>Inactive</td>
        }
        return (
            <tr>
                <td>{this.props.user.name}</td>
                <td>{this.props.user.email}</td>
                {this.props.user.user_type === 1 && <td>Super Administrator</td>}
                {this.props.user.user_type === 2 && <td>Admin User</td>}
                {this.props.user.user_type === 3 && <td>Partners/ Resellers</td>}
                {this.props.user.user_type === 4 && <td>Admin Viewer</td>}
                {adminStats}
                <td>{(new Date(this.props.user.create_date)).toDateString()}</td>
                <td><a href={'/edit-user/' + this.props.user._id} className="uk-button uk-button-small small_slider_btn">Manage</a></td>
                {this.props.user.status === 0 && <td></td>}
                {this.props.user.status === 1 && <td className='uk-preserve-width'><a data-uk-tooltip="Deactivate This User" href='/manage-user' onClick={this.deactivateUser} className="red"><span data-uk-icon="icon: eye-slash; ratio: 1.2"></span></a></td>}
                {this.props.user.status === 2 && <td className='uk-preserve-width'><a data-uk-tooltip="Activate This User" href='/manage-user' onClick={this.activateUser} className="green"><span data-uk-icon="icon: eye; ratio: 1.2"></span></a></td>}
                <td className='uk-preserve-width'><a data-uk-tooltip="Delete This User" href='/manage-user' onClick={this.deleteUser} className="red"><span data-uk-icon="icon: trash; ratio: 1.2"></span></a></td>
            </tr>
        )
    }
}



class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            status: 0,
            user_type: '',
            users: [],
            user: []
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.checkcookie()
        this.getUsers()
        this.getUser()

    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '2') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '4') {
            this.props.history.push('/dashboard')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getUsers = () => {
        axios.get(API_URL + '/api/getuser/')
            .then((response) => {
                this.setState({
                    users: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        var comp = this
        const payload = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            status: this.state.status,
            user_type: this.state.user_type
        }
        axios.post(API_URL + '/api/createuser/', payload)
            .then(function (response) {
                if (response.status === 201 && response.data !== '') {
                    comp.setState({
                        isProcessing: false,
                        showResult: true
                    }, function () {
                        alert('New Admin User Created!')
                        window.location = '/manage-user'
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        return (
            <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                <div className='uk-width-auto'>
                    <SideNav />
                </div>
                <div className='uk-width-expand uk-padding-remove'>
                    <div className="uk-width-1-1">
                        <div className="uk-card uk-card-default uk-card-body uk-card-small">
                            <div data-uk-grid>
                                <div className='uk-width-1-3'>
                                    <img src={logo} width='100' alt='zinox logo' />
                                </div>
                                <div className='uk-width-2-3 uk-text-right'>

                                    <h5 className='uk-text-bold'> {this.state.user.name}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='uk-padding'>
                            <div className="uk-card uk-card-default uk-card-body ">
                                <div data-uk-grid className=''>
                                    <div className='uk-width-auto '>
                                        <img src={manageUser} width='80' alt='icon' />
                                    </div>
                                    <div className='uk-width-expand uk-margin-small-top'>
                                        <h3 className='uk-text-left uk-margin-remove'>User Management</h3>
                                        <p className='uk-text-small uk-width-medium uk-margin-remove'>Manage Zinox Administrator Users</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="uk-text-left" data-uk-grid>
                                    <div className="uk-width-1-1@m">
                                        <div className="uk-background-muted uk-padding">
                                            <form method='POST' onSubmit={this.handleSubmit} data-uk-grid>
                                                <div className='uk-width-1-1@m'></div>
                                                <div className="uk-margin uk-width-1-2@m">
                                                    <label className="uk-form-label uk-text-bold ">Name <span className='red'>*</span></label>
                                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='name' placeholder="Admin Name" onChange={this.handleInputChange} required/>
                                                </div>
                                                <div className="uk-margin uk-width-1-2@m">
                                                    <label className="uk-form-label uk-text-bold ">Email <span className='red'>*</span></label>
                                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='email' placeholder="Email Address" onChange={this.handleInputChange} required/>
                                                </div>
                                                <div className="uk-margin uk-width-1-2@m">
                                                    <label className="uk-form-label uk-text-bold ">Temporary Password  <span className='red'>*</span></label>
                                                    <input className="uk-input calc_input uk-margin-small-top" type="password" name='password' placeholder="**********" onChange={this.handleInputChange} required/>
                                                </div>

                                                <div className="uk-margin uk-width-1-2@m">
                                                    <label className="uk-form-label uk-text-bold ">User Type <span className='red'>*</span></label>
                                                    <select className="uk-select calc_input uk-margin-small-top" name='user_type' onChange={this.handleInputChange} required>
                                                        <option value='' defaultValue >Select a Type</option>
                                                        <option value='1'>Super Administrator</option>
                                                        <option value='2'>Admin User</option>
                                                        <option value='3'>Partners/ Resellers</option>
                                                        <option value='4'>Admin Viewer</option>
                                                    </select>
                                                </div>
                                                <div className="uk-margin-top uk-width-1-2@m">
                                                    {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Add User</button>}
                                                    {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Adding New Admin User</h4>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="uk-width-1-1@m">
                                        <div className="uk-card uk-card-default uk-card-body">
                                            <div className='uk-margin'>
                                                <div className="uk-overflow-auto">
                                                    <h3 className='uk-text-left uk-margin'>User Details</h3>

                                                    <p className='uk-text-left uk-text-meta uk-margin-remove'>Showing {(this.state.users).length} Admin Users</p>
                                                    <table className="uk-table uk-table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th className='uk-text-bold red'>Name</th>
                                                                <th className='uk-text-bold red'>Email</th>
                                                                <th className='uk-text-bold red'>Admin Type</th>
                                                                <th className='uk-text-bold red'>Status</th>
                                                                <th className='uk-text-bold red'>Date Created</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.users.map(user =>
                                                                    <TableRow key={user._id}
                                                                        index={user._id}
                                                                        user={user} />
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AddUser;

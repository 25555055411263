import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import overview from '../img/overview.svg'
import totalDevices from '../img/total_devices.svg'
import activeWarranty from '../img/active_waranty.svg'
import expiredDevices from '../img/expired_devices.svg'
import tools from '../img/tools.svg'
import addProduct from '../img/add_product.svg'
import uploadSerial from '../img/upload_serial.svg'
import activateWarranty from '../img/activate _warranty.svg'
import inactiveWarranty from '../img/inactive_warranty.svg'
import searchWarranty from '../img/search_warranty.svg'
import editProduct from '../img/edit_product.svg'
import manageUser from '../img/manage_user.svg'

const cookies = new Cookies();

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            totalActiveDevices: 0,
            totalInactiveDevices: 0,
            totalExpiredDevices: 0
        }
    }
    componentDidMount() {
        this.checkcookie()
        this.getUser()
        this.getActiveDevices()
        this.getInactiveDevices()
        this.getExpiredDevices()
    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getActiveDevices = () => {
        axios.get(API_URL + '/api/allactiveserials')
            .then((response) => {
                this.setState({
                    totalActiveDevices: response.data.length,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getInactiveDevices = () => {
        axios.get(API_URL + '/api/allinactiveserials')
            .then((response) => {
                this.setState({
                    totalInactiveDevices: response.data.length,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getExpiredDevices = () => {
        axios.get(API_URL + '/api/allexpiredserials')
            .then((response) => {
                this.setState({
                    totalExpiredDevices: response.data.length,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }

    render() {
        return (
            <div>
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>
                                    </div>
                                </div>
                            </div>
                            {/* (cookies.get('user_type') === '2') {} */}

                            {(cookies.get('user_type') === '1') &&
                                <div className='uk-padding '>
                                    <h3 className='red uk-text-bold'>Hello, Zinox Administrator  ({this.state.user.name}) </h3>
                                    <p>Welcome to your Dashboard</p>
                                </div>
                            }
                            {(cookies.get('user_type') === '2') &&
                                <div className='uk-padding '>
                                    <h3 className='red uk-text-bold'>Hello, Zinox Administrator  ({this.state.user.name}) </h3>
                                    <p>Welcome to your Dashboard</p>
                                </div>
                            }

                            {(cookies.get('user_type') === '3') &&
                                <div className='uk-padding '>
                                    <h3 className='red uk-text-bold'>Hello, Zinox Partners/Resellers ({this.state.user.name}) </h3>
                                    <p>Welcome to your Dashboard</p>
                                </div>
                            }
                            {(cookies.get('user_type') === '4') &&
                                <div className='uk-padding '>
                                    <h3 className='red uk-text-bold'>Hello, Zinox Administrator  ({this.state.user.name}) </h3>
                                    <p>Welcome to your Dashboard</p>
                                </div>
                            }






                            {(cookies.get('user_type') === '1') &&
                                <div className='uk-padding'>
                                    <div data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={overview} alt='overview' width='15' /> Overview</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-top'>
                                        <div className="uk-card uk-card-default uk-card-body uk-width-1-1@l">
                                            <div data-uk-grid>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={totalDevices} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold amber'>{this.state.totalActiveDevices + this.state.totalInactiveDevices + this.state.totalExpiredDevices}</h1>
                                                            <p className='uk-margin-remove'>Total Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={activeWarranty} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold green_2'>{this.state.totalActiveDevices}</h1>
                                                            <p className='uk-margin-remove'>Active Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={expiredDevices} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold red'>{this.state.totalExpiredDevices}</h1>
                                                            <p className='uk-margin-remove'>Expired Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={inactiveWarranty} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold black'>{this.state.totalInactiveDevices}</h1>
                                                            <p className='uk-margin-remove'>Inactive Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={tools} alt='overview' width='15' /> Admin Tools</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/add-product'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={addProduct} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Add New Product</h4>
                                                    <p className='uk-text-small'>Add New Product and Warranty Duration Period</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/upload-single-serial'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={uploadSerial} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Add Single Serials</h4>
                                                    <p className='uk-text-small'>Add Single Serial Numbers for Zinox Products</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/upload-serial'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={uploadSerial} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Upload Serials</h4>
                                                    <p className='uk-text-small'>Upload Serial Numbers for Zinox Products (csv)</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/activate-warranty'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Activate Warranty</h4>
                                                    <p className='uk-text-small'>Activate Warranty for Products purchased</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/batch-activate-warranty'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Batch Warranty Activation</h4>
                                                    <p className='uk-text-small'>Batch Activate Warranty</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/warranty-check'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Check Warranty Status</h4>
                                                    <p className='uk-text-small'>Check product warranty</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/search'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={searchWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Search</h4>
                                                    <p className='uk-text-small'>Search for Product Serial & Warranty Information</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/search-product'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={editProduct} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Search & Edit Products</h4>
                                                    <p className='uk-text-small'>Search and Edit Product Information</p>
                                                </div>
                                            </a>
                                        </div>

                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/manage-user'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={manageUser} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">User Management</h4>
                                                    <p className='uk-text-small'>Manage Zinox Administrator Users</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }

                            {(cookies.get('user_type') === '2') &&
                                <div className='uk-padding'>
                                    <div data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={overview} alt='overview' width='15' /> Overview</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-top'>
                                        <div className="uk-card uk-card-default uk-card-body uk-width-1-1@l">
                                            <div data-uk-grid>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={totalDevices} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold amber'>{this.state.totalActiveDevices + this.state.totalInactiveDevices + this.state.totalExpiredDevices}</h1>
                                                            <p className='uk-margin-remove'>Total Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={activeWarranty} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold green_2'>{this.state.totalActiveDevices}</h1>
                                                            <p className='uk-margin-remove'>Active Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={expiredDevices} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold red'>{this.state.totalExpiredDevices}</h1>
                                                            <p className='uk-margin-remove'>Expired Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={inactiveWarranty} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold black'>{this.state.totalInactiveDevices}</h1>
                                                            <p className='uk-margin-remove'>Inactive Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={tools} alt='overview' width='15' /> Admin Tools</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/add-product'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={addProduct} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Add New Product</h4>
                                                    <p className='uk-text-small'>Add New Product and Warranty Duration Period</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/upload-single-serial'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={uploadSerial} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Add Single Serials</h4>
                                                    <p className='uk-text-small'>Add Single Serial Numbers for Zinox Products</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/upload-serial'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={uploadSerial} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Upload Serials</h4>
                                                    <p className='uk-text-small'>Upload Serial Numbers for Zinox Products (csv)</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/activate-warranty'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Activate Warranty</h4>
                                                    <p className='uk-text-small'>Activate Warranty for Products purchased</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/batch-activate-warranty'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Batch Warranty Activation</h4>
                                                    <p className='uk-text-small'>Batch Activate Warranty</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/warranty-check'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Check Warranty Status</h4>
                                                    <p className='uk-text-small'>Check product warranty</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/search'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={searchWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Search</h4>
                                                    <p className='uk-text-small'>Search for Product Serial & Warranty Information</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/search-product'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={editProduct} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Search & Edit Products</h4>
                                                    <p className='uk-text-small'>Search and Edit Product Information</p>
                                                </div>
                                            </a>
                                        </div>


                                    </div>
                                </div>
                            }
                            {(cookies.get('user_type') === '3') &&
                                <div className='uk-padding '>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={tools} alt='overview' width='15' /> Admin Tools</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/upload-single-serial'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={uploadSerial} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Add Single Serials</h4>
                                                    <p className='uk-text-small'>Add Single Serial Numbers for Zinox Products</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/upload-serial'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={uploadSerial} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Upload Serials</h4>
                                                    <p className='uk-text-small'>Upload Serial Numbers for Zinox Products (csv)</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/warranty-check'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Check Warranty Status</h4>
                                                    <p className='uk-text-small'>Check product warranty</p>
                                                </div>
                                            </a>
                                        </div>

                                    </div>
                                </div>
                            }
                            {(cookies.get('user_type') === '4') &&
                                <div className='uk-padding'>
                                    <div data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={overview} alt='overview' width='15' /> Overview</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-top'>
                                        <div className="uk-card uk-card-default uk-card-body uk-width-1-1@l">
                                            <div data-uk-grid>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={totalDevices} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold amber'>{this.state.totalActiveDevices + this.state.totalInactiveDevices + this.state.totalExpiredDevices}</h1>
                                                            <p className='uk-margin-remove'>Total Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={activeWarranty} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold green_2'>{this.state.totalActiveDevices}</h1>
                                                            <p className='uk-margin-remove'>Active Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={expiredDevices} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold red'>{this.state.totalExpiredDevices}</h1>
                                                            <p className='uk-margin-remove'>Expired Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                                    <div data-uk-grid className='uk-grid-small'>
                                                        <div className='uk-width-auto '>
                                                            <img src={inactiveWarranty} width='80' alt='icon' />
                                                        </div>
                                                        <div className='uk-width-expand'>
                                                            <h1 className='uk-margin-remove uk-text-bold black'>{this.state.totalInactiveDevices}</h1>
                                                            <p className='uk-margin-remove'>Inactive Warranty Devices</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                        <div className='uk-width-auto'> <p className='uk-text-bold'> <img src={tools} alt='overview' width='15' /> Admin Tools</p> </div>
                                        <div className='uk-width-expand'> <hr className='uk-margin-small-top' /> </div>
                                    </div>
                                    <div className='uk-margin-medium-top' data-uk-grid>
                                       
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/warranty-check'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={activateWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Check Warranty Status</h4>
                                                    <p className='uk-text-small'>Check product warranty</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className='uk-width-1-5@xl uk-width-1-4@l uk-width-1-3@m uk-width-1-2@s'>
                                            <a href='/search'>
                                                <div className="uk-card uk-card-default uk-card-body uk-text-center tool_card">
                                                    <img src={searchWarranty} width='100' alt='icon' />

                                                    <h4 className="uk-text-normal">Search</h4>
                                                    <p className='uk-text-small'>Search for Product Serial & Warranty Information</p>
                                                </div>
                                            </a>
                                        </div>
                                       


                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>

            </div >
        );
    }
}

export default Dashboard;

import React from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import activateWarrranty from '../img/activate _warranty.svg'
import loader from '../img/loader.svg'
import activeWarranty from '../img/active_warranty.svg'
import expiredWarranty from '../img/expired_warranty.svg'
import noWarranty from '../img/no_warranty.svg'
const cookies = new Cookies();


class ActivationResult extends React.Component {
    render() {
        return (
            <div>
                {this.props.data.deviceWarrantyActivated && <h4 className='uk-text-left uk-text-bold green'><img className='uk-margin-right' src={activeWarranty} alt='active warranty ' />Product Warranty Activated</h4>}
                {this.props.data.deviceActive && <h4 className='uk-text-left uk-text-bold amber'><img className='uk-margin-right' src={expiredWarranty} alt='expired warranty ' />Product Warranty Already Activated</h4>}
                {this.props.data.deviceNotFound && <h4 className='uk-text-left uk-text-bold red'><img className='uk-margin-right' src={noWarranty} alt='no warranty ' />Product Not Found!</h4>}
                {this.props.data.deviceActive &&
                    <div data-uk-grid className='uk-grid-small'>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Device Name</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{this.props.data.deviceActiveContent.product_id[0].item_detail + ' - ' + this.props.data.deviceActiveContent.product_id[0].item_number}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Device Model</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{this.props.data.deviceActiveContent.product_id[0].item_number}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Serial Number</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{this.props.data.deviceActiveContent.serial}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Device Information</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>	{this.props.data.deviceActiveContent.product_id[0].description}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Warranty type</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>Original Manufacturer Warranty</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Start Date</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{(new Date(this.props.data.deviceActiveContent.warranty_start_date).toDateString())}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>End Date</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{(new Date(this.props.data.deviceActiveContent.warranty_end_date).toDateString())}</p>
                        </div>

                    </div>
                }
                {this.props.data.deviceWarrantyActivated &&
                    <div data-uk-grid className='uk-grid-small'>

                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Serial Number</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{this.props.data.deviceWarrantyContent.serial}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Warranty type</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>Original Manufacturer Warranty</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>Start Date</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{(new Date(this.props.data.deviceWarrantyContent.warranty_start_date).toDateString())}</p>
                        </div>
                        <div className='uk-width-1-4@m'>
                            <h5 className='uk-text-bold'>End Date</h5>
                        </div>
                        <div className='uk-width-3-4@m'>
                            <p>{(new Date(this.props.data.deviceWarrantyContent.warranty_end_date).toDateString())}</p>
                        </div>

                    </div>
                }
                {this.props.data.deviceNotFound &&
                    <div data-uk-grid className='uk-grid-small'>

                        <div className='uk-width-2-3@m'>
                            <div className='red_card uk-card-body'>
                                <p>
                                    The Product Serial : <span className='uk-text-bold'>{this.props.data.serial_number}</span> Cannot be found in the system.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                }
                <hr />

                <a href='/activate-warranty' className="uk-button uk-margin uk-margin-right slider_btn">Activate Another Warranty</a>
                <a href='/dashboard' className="uk-button uk-margin slider_btn">Dashboard</a>
            </div>
        )
    }
}


class ActivateWarrranty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formError: false,
            product: [],
            product_id: '',
            serial_number: '',
            date_sale: '',
            warranty_start_date: '',
            warranty_end_date: '',
            proof_purchase: [],
            isProcessing: false,
            isProcessingSerialCheck: false,
            showResult: false,
            deviceNotFound: false,
            deviceActive: false,
            deviceActiveContent: [],
            deviceReadytoActivate: false,
            deviceReadyContent: [],
            deviceWarrantyActivated: false,
            deviceWarrantyContent: [],


        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.checkSerial = this.checkSerial.bind(this);

    }
    componentDidMount() {
        this.checkcookie()
        this.getProduct()
    }
    getProduct = () => {
        axios.get(API_URL + '/api/products')
            .then((response) => {
                this.setState({
                    product: response.data
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '4') {
            this.props.history.push('/dashboard')
        }

    }

    
    handleInputChange(event) { //stores input values in states
        switch (event.target.name) {
            case 'proof_purchase':
                this.setState({ proof_purchase: event.target.files[0] }, function () {
                });
                break;
            default:
                this.setState({ [event.target.name]: event.target.value });
        }
    }
    handleChange = event => {
        this.setState({ product_id: event.value });
    };

    checkSerial = () => {
        if (this.state.product_id === '' || this.state.serial_number === '') {
            this.setState({
                formError: true,
            });
        }
        else {
            this.setState({
                formError: false,
                isProcessingSerialCheck: true
            });
            const payload = {
                serial: this.state.serial_number,
                product_id: this.state.product_id
            }
            axios.post(API_URL + '/api/checkwarrantystatus', payload)
                .then((response) => {
                    console.log('checkSerial response:', response)
                    this.setState({
                        isProcessingSerialCheck: false
                    })
                    if (response.status === 204 && response.data === '') {
                        this.setState({
                            deviceNotFound: true,
                            showResult: true
                        })
                    }
                    if (response.status === 200 && response.data[0].status === 1) {
                        this.setState({
                            deviceActive: true,
                            showResult: true,
                            deviceActiveContent: response.data[0]
                        })
                    }
                    if (response.status === 200 && response.data[0].status === 0) {
                        this.setState({
                            deviceReadytoActivate: true,
                            deviceReadyContent: response.data[0],
                            deviceActiveContent: []
                        })
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error)
                    }
                });

        }
    };
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });

        var dateSale = new Date(this.state.date_sale)
        var endDate = new Date(dateSale.setMonth(dateSale.getMonth() + this.state.deviceReadyContent.product_id[0].warranty_period));
        console.log(dateSale.toISOString())
        let formData = new FormData();
        formData.append('date_sale', this.state.date_sale);
        formData.append('warranty_start_date', this.state.date_sale);
        formData.append('warranty_end_date', endDate.toISOString());
        formData.append('proof_purchase', this.state.proof_purchase);
        formData.append('status', 1);

        axios.put(API_URL + '/api/activatewarranty/' + this.state.deviceReadyContent._id, formData)
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    this.setState({
                        showResult: true,
                        deviceWarrantyActivated: true,
                        deviceWarrantyContent: response.data
                    })
                    console.log(this.state.deviceWarrantyActivated)
                    console.log(this.state.deviceWarrantyContent)

                }

            })
            .catch(error => {
                if (error.response) {
                    console.error('err resp', error.response)
                }
            });
    }
    renderForm() {
        const options = this.state.product.map((product, index) => {
            return {
                label: product.device_model,
                value: product._id,
                key: index
            }
        })
        return (
            <div>
                <div className='uk-width-2-5@l uk-width-3-5@m'>
                    <div className="uk-background-muted uk-padding">
                        <div className="uk-margin">
                            <h4 className='uk-text-bold'>Activate Warranty</h4>
                            {this.state.formError &&
                                <div className="uk-alert-danger" data-uk-alert>
                                    <p>Error! Product and/or Serial Missing</p>
                                </div>}
                            <form encType="multipart/form-data" method='POST' onSubmit={this.handleSubmit} >
                                <div className="uk-margin">

                                    <label className="uk-form-label uk-text-bold ">Device Model <span className='red'>*</span></label>
                                    <Select className="calc_input" options={options} name='product_id' onChange={this.handleChange} required />

                                </div>
                                <div className="uk-margin">
                                    <label className="uk-form-label uk-text-bold ">Serial Number <span className='red'>*</span></label>
                                    <input className="uk-input calc_input uk-margin-small-top" type="text" name='serial_number' placeholder="Serial Number" onChange={this.handleInputChange} required />
                                </div>
                                <div className="uk-margin">
                                    {!this.state.isProcessingSerialCheck && !this.state.deviceReadytoActivate && <button onClick={this.checkSerial} className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Check Serial</button>}
                                    {this.state.isProcessingSerialCheck && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Checking Serial Number.....</h4>}
                                </div>
                                {this.state.deviceReadytoActivate &&
                                    <div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-bold ">Date of Sale <span className='red'>*</span></label>
                                            <input className="uk-input calc_input uk-margin-small-top" type="date" name='date_sale' placeholder="Date of Sale" onChange={this.handleInputChange} required />
                                        </div>
                                        <div className="uk-margin">
                                            <label className="uk-form-label uk-text-bold ">Upload Proof of Purchase <span className='red'>*</span></label>
                                            <div data-uk-form-custom="target: true">
                                                <input type="file" onChange={this.handleInputChange} name='proof_purchase' />
                                                <input className="uk-input calc_input uk-form-width-large input" type="text" placeholder="Select file" disabled />
                                            </div>
                                        </div>
                                        <div className="uk-margin-large-top">
                                            {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Activate</button>}
                                            {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Upload Processing.....</h4>}
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    render() {
        if (this.state.showResult) {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> Zinox Admin</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={activateWarrranty} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Activate Warranty</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Activate Warranty for Products purchased </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <ActivationResult data={this.state} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> Zinox Admin</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={activateWarrranty} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Activate Warranty</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Activate Warranty for Products purchased </p>
                                        </div>
                                    </div>
                                    <hr />
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}


export default ActivateWarrranty;

import React from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import activateWarrranty from '../img/activate _warranty.svg'
import activeWarranty from '../img/active_warranty.svg'
import expiredWarranty from '../img/expired_warranty.svg'
import noWarranty from '../img/no_warranty.svg'
import Question from '../img/question.svg'
import serial from '../img/serial.png'



import loader from '../img/loader.svg'
const cookies = new Cookies();

class WarrantyResult extends React.Component {
    render() {
        if (this.props.data.deviceActiveContent) {
            var today = new Date();
            var status = ""
            if (new Date(this.props.data.deviceActiveContent.warranty_end_date) >= today) {
                status = <h4 className='uk-text-left uk-text-bold '><img className='uk-margin-right' src={activeWarranty} alt='active warranty  ' />Warranty Status for Device: <span className='green'>Active</span></h4>
            }
            else if (new Date(this.props.data.deviceActiveContent.warranty_end_date) < today) {
                status = <h4 className='uk-text-left uk-text-bold '><img className='uk-margin-right' src={expiredWarranty} alt='expired warranty ' />Warranty Status for Device: <span className='amber'>Expired (Out of Warranty)</span></h4>
            }
        }
        return (
            <div>
                <section>
                        <hr />
                        {status}
                        {this.props.data.deviceNotFound && <h4 className='uk-text-left uk-text-bold red'><img className='uk-margin-right' src={noWarranty} alt='no warranty ' />Product Not Found!</h4>}
                        {this.props.data.deviceActive &&
                            <div data-uk-grid className='uk-grid-small'>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Device Name</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{this.props.data.deviceActiveContent.product_id[0].item_detail + ' - ' + this.props.data.deviceActiveContent.product_id[0].item_number}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Device Model</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{this.props.data.deviceActiveContent.product_id[0].item_number}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Serial Number</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{this.props.data.deviceActiveContent.serial}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Device Information</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>	{this.props.data.deviceActiveContent.product_id[0].description}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Warranty type</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>Original Manufacturer Warranty</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>Start Date</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{(new Date(this.props.data.deviceActiveContent.warranty_start_date).toDateString())}</p>
                                </div>
                                <div className='uk-width-1-4@m'>
                                    <h5 className='uk-text-bold'>End Date</h5>
                                </div>
                                <div className='uk-width-3-4@m'>
                                    <p>{(new Date(this.props.data.deviceActiveContent.warranty_end_date).toDateString())}</p>
                                </div>

                            </div>
                        }
                        {this.props.data.deviceNotFound &&
                            <div data-uk-grid className='uk-grid-small'>

                                <div className='uk-width-2-3@m'>
                                    <div className='red_card uk-card-body'>
                                        <p>
                                            The Product Serial Cannot be found in our system.
                                            <br /><br />
                                            Think this is an error, Please contact zinox Support for futher assistance mail support@zinoxtechnologies.com <br /><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                        <hr />

                        <a href='/warranty-check' className="uk-button uk-margin uk-margin-right slider_btn">Check Another Product</a>
                </section>
            </div>
        )
    }
}

class WarrantyCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            product: [],
            product: [],
            product_id: '',
            serial: '',
            isProcessing: false,
            showResult: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        this.checkcookie()
        this.getProducts()
        this.getUser()

    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getProducts = () => {
        axios.get(API_URL + '/api/products/')
            .then((response) => {
                this.setState({
                    product: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChange = event => {
        this.setState({ product_id: event.value });
    };
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        var comp = this
        const payload = {
            serial: this.state.serial,
            product_id: this.state.product_id
        }
        axios.post(API_URL + '/api/checkwarrantystatus', payload)
            .then((response) => {
                console.log('checkSerial response:', response)
                this.setState({
                    isProcessingSerialCheck: false
                })
                if (response.status === 204 && response.data === '') {
                    this.setState({
                        deviceNotFound: true,
                        showResult: true
                    })
                }
                if (response.status === 200 && response.data[0].status === 1) {
                    this.setState({
                        deviceActive: true,
                        showResult: true,
                        deviceActiveContent: response.data[0]
                    })
                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    renderForm() {
        const options = this.state.product.map((product, index) => {
            return {
                label: product.device_model,
                value: product._id,
                key: index
            }
        })
        return (
            <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                <div className='uk-width-auto'>
                    <SideNav />
                </div>
                <div className='uk-width-expand uk-padding-remove'>
                    <div className="uk-width-1-1">
                        <div className="uk-card uk-card-default uk-card-body uk-card-small">
                            <div data-uk-grid>
                                <div className='uk-width-1-3'>
                                    <img src={logo} width='100' alt='zinox logo' />
                                </div>
                                <div className='uk-width-2-3 uk-text-right'>

                                    <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                </div>
                            </div>
                        </div>
                        <div className='uk-padding'>
                            <div className="uk-card uk-card-default uk-card-body ">
                                <div data-uk-grid className=''>
                                    <div className='uk-width-auto '>
                                        <img src={activateWarrranty} width='80' alt='icon' />
                                    </div>
                                    <div className='uk-width-expand uk-margin-small-top'>
                                        <h3 className='uk-text-left uk-margin-remove'>Check Warranty Status</h3>
                                        <p className='uk-text-small uk-width-medium uk-margin-remove'>Check a single product warranty</p>
                                    </div>
                                    <div className='uk-width-1-1@m'>
                                        <div className="uk-background-muted uk-padding">
                                            <div className="uk-margin">
                                                <h4 className='uk-text-bold'>Warranty Check</h4>
                                                <form method='POST' onSubmit={this.handleSubmit} >
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label uk-text-bold ">Device Model <span className='red'>*</span></label>
                                                        <Select className="calc_input" options={options} name='product_id' onChange={this.handleChange} required />
                                                    </div>
                                                    <div className="uk-margin">
                                                        <label className="uk-form-label uk-text-bold ">Serial Number <span className='red'>* </span>
                                                            <a href="#modal-sections" data-uk-tooltip="How to find serial" data-uk-toggle="target: #serial"><img src={Question} alt='icon' width='13' /></a></label>
                                                        <div className="calc_input uk-margin-small-top">
                                                            <input className="uk-input " id="form-stacked-text" type="text" name='serial' placeholder="Serial Number" onChange={this.handleInputChange} required />
                                                        </div>
                                                        <p className='uk-text-meta black uk-margin-small serial_example'>Example: ZAIOi3Q-0001</p>
                                                    </div>
                                                    <div id="serial" data-uk-modal>
                                                        <div className="uk-modal-dialog uk-margin-auto-vertical uk-width-3-5@m">
                                                            <button className="uk-modal-close-default" type="button" data-uk-close></button>
                                                            <div className="uk-modal-header">
                                                                <h4 className="uk-text-bold"><img src={logo} alt='logo' width='100' />How do I find my Device Model or serial number?</h4>
                                                            </div>
                                                            <div className="uk-modal-body">
                                                                <div data-uk-grid>
                                                                    <div className='uk-width-1-3@m'>
                                                                        <img src={serial} alt='serial' />
                                                                    </div>
                                                                    <div className='uk-width-2-3@m'>
                                                                        <h4 className="uk-text-bold red">Instructions</h4>
                                                                        <p>
                                                                            How to find your serial number:
                                                                            Zinox laptops, tablets and Desktop serial numbers may be located in one of these locations.
                                                                        </p>
                                                                        <ol>
                                                                            <li>· On the bottom or back edge of the laptop.</li>
                                                                            <li>· If the battery is removable, inside the battery compartment.</li>
                                                                            <li>· If the laptop separates into a tablet, on the edge where the tablet connects to the keyboard.</li>
                                                                            <li>· For Desktops, the serial number is found on the side of the desktop.</li>
                                                                            <li>· For "all in one" models, the serial number is either located on the back of the screen or under the base.</li>

                                                                        </ol>
                                                                        <p>
                                                                            Televisions:
                                                                            · At the back of the TV set
                                                                            Generators:
                                                                            · On the engine block
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="uk-modal-footer uk-text-right">
                                                                <p className='uk-text-meta'>© 2020 Zinox Technologies Ltd.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="uk-margin-large-top">
                                                        {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Check Warranty</button>}
                                                        {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Checking for Warranty</h4>}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    render() {
        if (this.state.showResult) {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={activateWarrranty} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Check Warranty Status</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Check a single product warranty</p>
                                        </div>
                                        <div className='uk-width-1-1@m'>
                                        <WarrantyResult data={this.state} />

                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else {
            return (
                this.renderForm()
            )
        }
    }
}


export default WarrantyCheck;

import React, { Component } from 'react';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from './SideNav'
import logo from '../img/logo.svg'
import Cookies from 'universal-cookie';
import loader from '../img/loader.svg'
import editProduct from '../img/edit_product.svg'

let productID = (window.location.href).split('/').pop();


const cookies = new Cookies();

class EditProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item_detail: '',
            item_number: '',
            device_code: '',
            description: '',
            device_name: '',
            warranty_period: '',
            isProcessing: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.products !== this.props.products) {
            this.setState({
                item_detail: this.props.products.item_detail,
                item_number: this.props.products.item_number,
                device_code: this.props.products.device_code,
                description: this.props.products.description,
                device_name: this.props.products.device_name,
                warranty_period: this.props.products.warranty_period,
            })
        }
    }

    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        var comp = this
        const payload = {
            item_detail: this.state.item_detail,
            item_number: this.state.item_number,
            device_code: this.state.device_code,
            description: this.state.description,
            device_name: this.state.device_name,
            warranty_period: this.state.warranty_period,
        }
        axios.put(API_URL + '/api/updateproduct/' + productID, payload)
            .then(function (response) {
                if (response.status === 200 && response.data !== '') {
                    comp.setState({
                        isProcessing: false,
                    }, function () {
                        alert('Product Updated')
                        window.location = '/search-product'
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {
        return (
            <form method='POST' onSubmit={this.handleSubmit} >

                <div className="uk-margin">
                    <label className="uk-form-label uk-text-bold ">Item Details <span className='red'>*</span></label>
                    <input className="uk-input calc_input uk-margin-small-top" value={this.state.item_detail} type="text" name='item_detail' placeholder="Item Details" onChange={this.handleInputChange} />
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label uk-text-bold ">Item Number <span className='red'>*</span></label>
                    <input className="uk-input calc_input uk-margin-small-top" value={this.state.item_number} type="text" name='item_number' placeholder="Item Number" onChange={this.handleInputChange} />
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label uk-text-bold ">Device Code <span className='red'>*</span></label>
                    <input className="uk-input calc_input uk-margin-small-top" value={this.state.device_code} type="text" name='device_code' placeholder="Device Code" onChange={this.handleInputChange} />
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label uk-text-bold ">Device Name <span className='red'>*</span></label>
                    <input className="uk-input calc_input uk-margin-small-top" value={this.state.device_name} type="text" name='device_name' placeholder="Device Name" onChange={this.handleInputChange} />
                </div>
                <div className="uk-margin">
                    <label className="uk-form-label uk-text-bold ">Description  <span className='red'>*</span></label>
                    <textarea className="uk-textarea calc_input  uk-margin-small-top" value={this.state.description} rows="3" placeholder="Description" name='description' onChange={this.handleInputChange}></textarea>
                </div>

                <div className="uk-margin">
                    <label className="uk-form-label uk-text-bold ">Warranty Period (In Months) <span className='red'>*</span></label>
                    <input className="uk-input calc_input uk-margin-small-top" type="number" name='warranty_period' value={this.state.warranty_period} placeholder="Enter Warranty Period in Months" onChange={this.handleInputChange} />

                    {/* <select className="uk-select calc_input uk-margin-small-top" value={this.state.warranty_period} name='warranty_period' onChange={this.handleInputChange}>
                        <option value='' defaultValue >Select a Period</option>
                        <option value='6'>6 Months</option>
                        <option value='12'>12 Months</option>
                        <option value='120'>10 Years</option>
                    </select> */}
                </div>
                <div className="uk-margin-large-top">
                    {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Update Product</button>}
                    {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Update Product Information</h4>}
                </div>
            </form>
        )
    }
}


export default class EditProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            user: []
        }
    }

    componentDidMount() {
        this.checkcookie()
        this.getUsers()
        this.getUser()
    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '2') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '4') {
            this.props.history.push('/dashboard')
        }
    }
    getUsers = () => {
        axios.get(API_URL + '/api/findproduct/' + productID)
            .then((response) => {
                this.setState({
                    products: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }

    render() {
        return (
            <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                <div className='uk-width-auto'>
                    <SideNav />
                </div>
                <div className='uk-width-expand uk-padding-remove'>
                    <div className="uk-width-1-1">
                        <div className="uk-card uk-card-default uk-card-body uk-card-small">
                            <div data-uk-grid>
                                <div className='uk-width-1-3'>
                                    <img src={logo} width='100' alt='zinox logo' />
                                </div>
                                <div className='uk-width-2-3 uk-text-right'>

                                    <h5 className='uk-text-bold'>  {this.state.user.name}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='uk-padding'>
                            <div className="uk-card uk-card-default uk-card-body ">
                                <div data-uk-grid className=''>
                                    <div className='uk-width-auto '>
                                        <img src={editProduct} width='80' alt='icon' />
                                    </div>
                                    <div className='uk-width-expand uk-margin-small-top'>
                                        <h3 className='uk-text-left uk-margin-remove'>Edit Product</h3>
                                        <p className='uk-text-small uk-width-medium uk-margin-remove'>Edit and Update Product Information</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="uk-text-left" data-uk-grid>
                                    <div className="uk-width-2-5@m">
                                        <div className="uk-background-muted uk-padding">
                                            <h4 className='uk-text-left uk-margin'>Currently Editing Product: <span className='red'>{this.state.products.device_model}</span></h4>
                                            <EditProductForm products={this.state.products} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}


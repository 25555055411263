import React from 'react';
import Select from 'react-select';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import logo from '../img/logo.svg'
import uploadSerial from '../img/upload_serial.svg'
import loader from '../img/loader.svg'
const cookies = new Cookies();


class UploadSerial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product_id: '',
            serial: '',
            user: [],
            products: [],
            isProcessing: false,
            showResult: false,
            serialExists: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
        this.checkcookie()
        this.getProducts()
        this.getUser()

    }
    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '4') {
            this.props.history.push('/dashboard')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    getProducts = () => {
        axios.get(API_URL + '/api/products/')
            .then((response) => {
                this.setState({
                    products: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ [event.target.name]: event.target.value });
    }

    handleChange = event => {
        this.setState({ product_id: event.value });
    };
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        const payload = {
            product_id: this.state.product_id,
            serial: this.state.serial
        }
        console.log(payload)
        axios.post(API_URL + '/api/warrantyserials/', payload)
            .then((response) => {
                // console.log(response)

                if (response.status === 201 && response.data !== '') {
                    this.setState({
                        isProcessing: false,
                        showResult: true
                    });
                }

            })
            .catch(error => {
                if (error.response.data) {
                    // console.error('err resp', error.response)
                    this.setState({ serialExists: true, isProcessing : false});
                }
            });
    }
    renderForm() {
        const options = this.state.products.map((product, index) => {
            return {
                label: product.device_model,
                value: product._id,
                key: index
            }
        })
        return (
            <div>
                <div className='uk-width-1-1@m'>
                    <div className="uk-background-muted uk-padding">
                        <div className="uk-margin">
                            <h4 className='uk-text-bold'>Add Serial</h4>
                            {this.state.serialExists &&
                                <div className="uk-alert-danger" data-uk-alert>
                                    <p>Error!   Serial already exists</p>
                                </div>}
                            <form data-uk-grid method='POST' onSubmit={this.handleSubmit} >
                                <br />
                                <div className="uk-margin uk-width-1-2@m">
                                    <label className="uk-form-label uk-text-bold ">Device Model <span className='red'>*</span></label>
                                    <Select className="calc_input uk-margin-small-top" options={options} name='product_id' onChange={this.handleChange} placeholder='Search...' required />
                                </div>
                                <div className="uk-margin uk-width-1-2@m">
                                    <label className="uk-form-label uk-text-bold ">Serial Number <span className='red'>*</span></label>
                                    <input className='uk-input calc_input uk-margin-small-top' type="text" onChange={this.handleInputChange} name='serial' required />
                                </div>
                                <br />
                                <div className="uk-margin-top uk-width-1-2@m">
                                    {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Add Serial</button>}
                                    {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Processing.....</h4>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    render() {
        if (this.state.showResult) {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={uploadSerial} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Add Single Serial</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Upload Serial Numbers for Zinox Products</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <h3 className='uk-text-left uk-margin-large'> Serial Added</h3>
                                    <a href='/upload-single-serial' className="uk-button uk-margin uk-margin-right slider_btn">Add Another Serial</a>
                                    <br />
                                    <a href='/dashboard' className="uk-button uk-margin slider_btn">Back to Dashboard</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={uploadSerial} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Add Single Serial</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Upload Serial Numbers for Zinox Products</p>
                                        </div>
                                    </div>
                                    <hr />
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}


export default UploadSerial;

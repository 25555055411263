import React from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API_URL } from './apiUrl'
import SideNav from '../components/SideNav'
import activateWarrranty from '../img/activate _warranty.svg'
import logo from '../img/logo.svg'
import loader from '../img/loader.svg'

const cookies = new Cookies();

class UploadResult extends React.Component {
    render() {
        console.log(this.props)
        return (
            <div>
                <h3 className='uk-text-left uk-margin-large'> Batch Warranty Activated</h3>
                {/* <a href='/upload-serial' className="uk-button uk-margin uk-margin-right slider_btn">Upload Another Serial</a> */}
                <a href='/dashboard' className="uk-button uk-margin slider_btn">Dashboard</a>
            </div>
        )
    }
}

class BatchActivateWarranty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: [],
            isProcessing: false,
            showResult: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.checkcookie()
        this.getUser()
    }

    checkcookie = () => {
        if (cookies.get('current_session') === "" || cookies.get('current_session') === undefined) {
            this.props.history.push('/')
        }
        if (cookies.get('user_type') === '3') {
            this.props.history.push('/dashboard')
        }
        if (cookies.get('user_type') === '4') {
            this.props.history.push('/dashboard')
        }
    }
    getUser = () => {
        axios.get(API_URL + '/api/getuser/' + cookies.get('current_session'))
            .then((response) => {
                this.setState({
                    user: response.data,
                })
            })
            .catch(error => {
                if (error.response) {
                    console.log(error)
                }
            });
    }
    handleInputChange(event) { //stores input values in states
        this.setState({ serial_file: event.target.files[0] })
    }
    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isProcessing: true,
        });
        let formData = new FormData();
        formData.append('serial_file', this.state.serial_file);

        axios.put(API_URL + '/api/batchactivatewarranty/', formData)
            .then((response) => {
                console.log(response)
                if (response.status === 200 && response.data !== '') {
                    this.setState({
                        // errorFormat: true,
                        isProcessing: false,
                        showResult: true
                    });
                }

            })
            .catch(error => {
                if (error.response) {
                    console.error('err resp', error.response)
                }
            });
    }
    renderForm() {
        return (
            <div>
                <div className='uk-width-1-2@m'>
                    <div className="uk-background-muted uk-padding">
                        <div className="uk-margin">
                            <h4 className='uk-text-bold'>Upload</h4>
                            {this.state.errorFormat &&
                                <div className="uk-alert-danger" data-uk-alert>
                                    <p>Error!   Wrong Serial Document Format Uploaded. Kindly Upload a Proper Document Format</p>
                                </div>}
                            <form data-uk-grid method='POST' onSubmit={this.handleSubmit} >
                                <div className="uk-margin uk-width-1-1@m">
                                    <label className="uk-form-label uk-text-bold ">Serial Number File <span className='red'>*</span></label>
                                    <div className='uk-margin-small-top' data-uk-form-custom="target: true">
                                        <input type="file" onChange={this.handleInputChange} name='serial_file' required />
                                        <input className="uk-input calc_input uk-form-width-large input" type="text" placeholder="Select file" disabled />
                                    </div>
                                </div>
                                <br />
                                <div className="uk-margin-top uk-width-2-3@m">
                                    {!this.state.isProcessing && <button type='submit' className="uk-button uk-margin-right uk-width-2-3@m slider_btn">Upload Serials</button>}
                                    {this.state.isProcessing && <h4 className="uk-form-label uk-text-bold "><img className='uk-margin-right' src={loader} width='40' alt='loader' /> Upload Processing.....</h4>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
    render() {
        if (this.state.showResult) {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={activateWarrranty} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Batch Activate Warranty</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Batch Activate Warranty for Products purchased </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <UploadResult data={this.state} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else {
            return (
                <section className="uk-grid-small uk-grid-match uk-text-left" data-uk-grid data-uk-height-viewport>
                    <div className='uk-width-auto'>
                        <SideNav />
                    </div>
                    <div className='uk-width-expand uk-padding-remove'>
                        <div className="uk-width-1-1">
                            <div className="uk-card uk-card-default uk-card-body uk-card-small">
                                <div data-uk-grid>
                                    <div className='uk-width-1-3'>
                                        <img src={logo} width='100' alt='zinox logo' />
                                    </div>
                                    <div className='uk-width-2-3 uk-text-right'>

                                        <h5 className='uk-text-bold'> {this.state.user.name}</h5>

                                    </div>
                                </div>
                            </div>
                            <div className='uk-padding'>
                                <div className="uk-card uk-card-default uk-card-body ">
                                    <div data-uk-grid className=''>
                                        <div className='uk-width-auto '>
                                            <img src={activateWarrranty} width='80' alt='icon' />
                                        </div>
                                        <div className='uk-width-expand uk-margin-small-top'>
                                            <h3 className='uk-text-left uk-margin-remove'>Batch Activate Warranty</h3>
                                            <p className='uk-text-small uk-width-medium uk-margin-remove'>Batch Activate Warranty for Products purchased </p>
                                        </div>
                                    </div>
                                    <hr />
                                    {this.renderForm()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}

export default BatchActivateWarranty
import Cookies from 'universal-cookie';
import React, { Component } from 'react';
var currentUrl = (window.location.href).split('/').pop();
const cookies = new Cookies();

class SideNav extends Component {
    render() {
        return (
            <div className=' dark_nav_bar uk-visible@s'>
                {(cookies.get('user_type') === '1' || cookies.get('user_type') === '2') &&
                    <div className='uk-padding uk-light'>
                        {/* <div className="" style={{ zIndex: '980' }} data-uk-sticky="offset: 100; bottom: #top"> */}
                        <ul className="uk-nav uk-nav-default">
                            {currentUrl === 'dashboard' ? <li><a className='white' href="/dashboard">Dashboard</a></li> : <li><a href="/dashboard">Dashboard</a></li>}
                            {currentUrl === 'add-product' ? <li><a className='white' href="/add-product">Add Product</a></li> : <li><a href="/add-product">Add Product</a></li>}
                            {currentUrl === 'upload-serial' ? <li><a className='white' href="/upload-serial">Upload Serial</a></li> : <li><a href="/upload-serial">Upload Serial</a></li>}
                            {currentUrl === 'upload-single-serial' ? <li><a className='white' href="/upload-single-serial">Add Single Serial</a></li> : <li><a href="/upload-single-serial">Add Single Serial</a></li>}
                            {currentUrl === 'activate-warranty' ? <li><a className='white' href="/activate-warranty">Activate Warranty</a></li> : <li><a href="/activate-warranty">Activate Warranty</a></li>}
                            {currentUrl === 'manage-user' ? <li><a className='white' href="/manage-user">User Management</a></li> : <li><a href="/manage-user">User Management</a></li>}
                            <li><a href="/logout">Logout</a></li>
                        </ul>
                        {/* </div> */}
                    </div>
                }
                {(cookies.get('user_type') === '3') &&
                    <div className='uk-padding uk-light'>
                        <ul className="uk-nav uk-nav-default">
                            {currentUrl === 'dashboard' ? <li><a className='white' href="/dashboard">Dashboard</a></li> : <li><a href="/dashboard">Dashboard</a></li>}
                            {currentUrl === 'upload-serial' ? <li><a className='white' href="/upload-serial">Upload Serial</a></li> : <li><a href="/upload-serial">Upload Serial</a></li>}
                            {currentUrl === 'upload-single-serial' ? <li><a className='white' href="/upload-single-serial">Add Single Serial</a></li> : <li><a href="/upload-single-serial">Add Single Serial</a></li>}
                            <li><a href="/logout">Logout</a></li>
                        </ul>
                    </div>
                }

                {(cookies.get('user_type') === '4') &&
                    <div className='uk-padding uk-light'>
                        <ul className="uk-nav uk-nav-default">
                            {currentUrl === 'dashboard' ? <li><a className='white' href="/dashboard">Dashboard</a></li> : <li><a href="/dashboard">Dashboard</a></li>}
                          
                            <li><a href="/logout">Logout</a></li>
                        </ul>
                    </div>
                }
            </div>

        )
    }
}
export default SideNav;

